import { render, staticRenderFns } from "./CollapsedItems.vue?vue&type=template&id=3cac7c03&scoped=true&"
import script from "./CollapsedItems.vue?vue&type=script&lang=ts&"
export * from "./CollapsedItems.vue?vue&type=script&lang=ts&"
import style0 from "./CollapsedItems.vue?vue&type=style&index=0&id=3cac7c03&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cac7c03",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VCard,VMenu})
